<template>
  <div>
    <div class="productDetails">
      <Breadcrumb title="My Cart"/>
    </div>
    <div style="min-height: 100vh; padding-bottom:50px; margin-bottom:-60px" class="product-background">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="product-heading">
              <span>
                Product
              </span>
              <span style="color:white;">|</span>
              <span>
                {{ product.category.name }}
              </span>

              <span>|</span>
              <span>{{product.name}}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="container">
        <div style="padding-bottom: 20px;" class="product-details-body">
          <div class="row">
            <div class="col-md-6 col-sm-12 card-img">
              <div class="card">
                <div class="card-body">
                  <img alt="" style="width:100%" class="img-fluid" :src="productImage">
                </div>
              </div>
            </div>

            <div class="col-md-6 m-padding col-sm-12">
              <!-- <div class="rating">
                <i class="fa fa-3x fa-star"></i>
                <i class="fa fa-3x fa-star"></i>
                <i class="fa fa-3x fa-star"></i>
                <i class="fa fa-3x fa-star"></i>
                <i class="fa fa-3x fa-star"></i>
              </div>-->
              <div class="product-text-content">
                  <h1 style="font-size: 25px" class="text-gray mt-3">{{product.name}}</h1>
                  <p class="text-justify">
                    <span v-html="product.description"></span>
                  </p>
                </div>
                  <p style="font-weight:bold" class="mt-5">Weight: {{ product.weight }} 
                    <span v-if="product.unit !== null">{{ product.unit.name }}</span>
                    <span v-if="product.variable_weight == 1">(+/-)</span>
                  </p>
                <div class="product-order mt-5 mb-35">
                  <span v-if="product.discount == 0" class="product-price">৳ {{ product.price }}</span>
                  <template v-if="product.discount != 0">
                    <span  class="product-price"> ৳ {{ product.price - product.discount }}
                      <del style="color:#8c8c8c">৳ {{ product.price }}</del>
                    </span>
  
                  </template>
                  <span
                    v-if="product.available"
                    class="add-cart"
                    @click="addToCart()"
                  >
                    Add to cart
                    <i class="fa fa-cart-plus"></i>
                  </span>
                  <span
                  v-if="!product.available"
                    class="disable"
                  >
                    Out of Stock
                    
                  </span>
                </div>
              <!-- <div class="product-text-content">
                <h1 style="font-size: 25px" class="text-gray mt-3">{{product.name}}</h1>
                <p class="text-justify">
                  <span v-html="product.description"></span>
                </p>
              </div>
                <p style="font-weight:bold" class="mt-5">Weight: {{ product.weight }}
                  <span v-if="product.unit !== null">{{ product.unit.name }}</span>
                  <span v-if="product.variable_weight == 1">(+/-)</span>
                </p>
              <div class="product-order mt-5 mb-35">
                <router-link class="btn add-cart view-store" :to="'/estore/products?id=' + product.e_store_category_id">Shop Now</Router-link>
              </div>
              <div v-if="product.nutrition_details" class="mt-5">
                <h2>Nutritions</h2>
                <div class="detail">
                    <p class="text-justify">
                      <span v-html="product.nutrition_details"></span>
                    </p>
                </div>
              </div> -->


              <div v-if="cookingDirections.length>0" class="direction mt-5">
                <h2>Cooking Directions</h2>
                <template v-for="dir in cookingDirections">
                    <div :key="dir.id" class="direction-detail mt-5">
                      <h6>{{ dir.title }}</h6>
                      <div class="detail">
                        <p class="text-justify">
                          <span v-html="dir.direction"></span>
                        </p>
                      </div>

                    </div>
                </template>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- snackbar -->
      <v-snackbar v-model="snackbar">
          {{ text }}
          <v-btn color="pink" text  @click="snackbar = false">
              Close
          </v-btn>
      </v-snackbar>
    <!-- snackbar -->
  </div>
</template>

<script>
import Breadcrumb from "@/components/common/BreadcrumbComponent";
export default {
  name: 'single-product',
  props: {
    id: {
      reqired: true
    }
  },
  components: { Breadcrumb },
  data() {
    return {
      snackbar: false,
      text: '',
      product: [],
      img: "https://s3.ap-south-1.amazonaws.com/german-butcher/",
      nutritions:[],
      cookingDirections:[],
    };
  },
  methods: {
    addToCart() {
      this.$store.commit("addTCart", this.product);
      localStorage.setItem(
        "cart",
        JSON.stringify(this.$store.getters.cart_Info)
      );
      this.snackbar = true
      this.text= 'Added to cart'
    },
    goToEstore(){
      this.$router.push({name: 'estore'})
    }
  },//end of methods
  computed: {
    productImage: function () {
      return this.product.images[0].path
    },
    type: function () {
      return this.$route.query.type
    }
  },
  watch: {
        $route() {
            let id = parseInt(this.id);
            this.axios.get("/product-detail?products_id=" + id).then(response => {
              this.product = response.data.product;
              this.nutritions=response.data.nutritions;
              this.cookingDirections=response.data.directions;
            });
        }
    },
  mounted() {
    let id = parseInt(this.id);
    this.axios.get("/product-detail?products_id=" + id).then(response => {
      this.product = response.data.product;
      this.nutritions=response.data.nutritions;
      this.cookingDirections=response.data.directions;
    });
  }
};
</script>

<style scoped>
  .product-info {
    background: #fff;
  }

  .product-heading {
    text-align: center;
    color: white;
    margin-top: 45px;
  }
  .product-heading span {
    padding: 5px;
    text-align: center;
    font-weight: bold;
    color: #fff;
  }
  .product-details-body {
    background-color: white;
    margin-top: 45px;
  }
  .decription {
    border-left: 1px solid rgb(3, 3, 3);
  }
  .card-img {
    padding-top: 15px;
    padding-left: 29px;
  }
  .border {
    border: 1px solid rgb(3, 3, 3);
  }
  .rating {
    direction: rtl;
    unicode-bidi: bidi-override;
    color: #ddd;
  }
  /* .product-text-content {
    margin-top: 100px;
  } */

  .nuturition-fact {
    position: relative;
    /* min-height: 100bh; */
    /* min-height: 200px; */
    background-color: black;
  }
  .product-nutration-area {
    background-color: white;
  }

  .product-background {
    /* background-image: url("/img/blog/Singlerecipe-1.jpg"); */
    background-color: #434343;
    background-repeat: no-repeat;

    background-size: cover;
    position: relative;
  }
  .add-cart{
    border: 1px solid #dbd7d7;
    padding: 10px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    color: #e54c2a;
    transition: .5s;
    margin-right: 20px;
  }
  .add-cart:hover{
    background-color: #e54c2a;
    color: #fff;
  }

  .product-price{
    border: 1px solid #dbd7d7;
    padding: 10px;
    font-size: 14px;
    font-weight: bold;
    color: #e54c2a;
    transition: .5s;
    margin-right: 20px;
  }

  .detail{
    padding: 20px;
    background-color: #f9efbb;
  }
  .card{
    border: 0 !important;
  }
  .card-body{
    padding: 0 !important;
    margin-top: 0.25rem !important;
  }
  .view-store {
    padding: 4px 15px;
    height: inherit;
  }
  @media only screen and (max-width: 576px){
    .container {
        max-width: 100%;
    }
  }
  @media only screen and (max-width: 768px){
    .m-padding {
        padding: 25px !important;
    }
  }

</style>

